import { AppError, ServiceError, ValidationError } from 'src/lib/errors';

export const getErrorMessageTitle = (error: AppError | ServiceError | Error): string => {
    switch ((error as ServiceError).status) {
        case 400: return 'It looks like something is invalid.';
        case 401: return 'Authentication failure, you are not logged in.';
        case 403: return 'You are not authorized to make this change.';
        default: return `There's a slight problem on this page.`;
    }
};

export const getFormatedErrorMessage = (error: AppError | ServiceError | Error): string => {
    switch ((error as ServiceError).status) {
        case 400:
            return JSON.stringify((error as ValidationError).validation);
        default:
            return error.message;
    }
};
