import { Cancel } from '@mui/icons-material';
import { ComponentProps } from 'react';

import { LoadingButton } from 'src/components/common/buttons/LoadingButton';

export const CancelLoadingButton = (props: ComponentProps<typeof LoadingButton>): JSX.Element => {
    const {
        Icon = Cancel,
        ...rest
    } = props;

    return (
        <LoadingButton {...rest} aria-label="cancel and reset changes" color="secondary" Icon={Icon} />
    );
};
