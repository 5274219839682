import { v4 } from 'uuid';

import * as utils from 'src/lib/errors/utils';

// TODO use a code for easy conditional handling of error
export class AppError extends Error {
    guid: string;

    status: number;

    constructor(payload: App.Error.Payload) {
        const message = utils.resolveMessage(payload);

        super(message);

        Object.assign(this, payload);

        if ('stack' in payload && !!payload.stack) {
            this.stack = ` ${payload.stack}`;
        } else if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, AppError);
        }

        this.name = 'AppError';
        this.guid = v4();
        this.status = utils.resolveStatus(payload);
    }
}
