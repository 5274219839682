import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { SearchForm } from 'src/components/ProductSearchPage/SearchForm';
import { TitledAppBar } from 'src/components/common/TitledAppBar';
import { getQueryStringValues } from 'src/lib/getQueryStringValues';
import { Suspense } from 'react';
import { SearchResults } from 'src/components/ProductSearchPage/SearchResults';
import { SearchResultsSkeleton } from 'src/components/ProductSearchPage/SearchResultsSkeleton';

const INITIAL_STATE = {
    productName: undefined,
    productKey: null,
    page: 1,
};

const StyledCSSGrid = styled(CSSGrid)(({ theme }) => ({
    padding: `0 ${theme.spacing(8)} `,
    minHeight: '100%',
    gridArea: 'content',
    gridTemplateRows: '1fr auto',
    gridTemplateAreas: `
        "results"
        "pagination"
    `,
}));

export const ProductSearchPage = (): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const search = getQueryStringValues() || INITIAL_STATE;

    const onSearch = async (newSearch: App.ProductSearch.PagedSearch): Promise<void> => {
        if (JSON.stringify(newSearch) !== JSON.stringify(search)) {
            navigate({
                ...location,
                search: qs.stringify({ ...newSearch, page: undefined }),
            });
        }
    };

    const title = !!search?.productKey || !!search?.productName
        ? `Search Products - ${search?.productKey || ''}${search?.productName || ''}`
        : 'Search Products';

    return (
        <>
            <TitledAppBar title={title}>
                <SearchForm search={search} onSubmit={onSearch} />
            </TitledAppBar>
            <StyledCSSGrid
                gridTemplateRows="auto 1fr"
                height="100%"
            >
                <Suspense fallback={<SearchResultsSkeleton />}>
                    <SearchResults search={search} />
                </Suspense>
            </StyledCSSGrid>
        </>
    );
};
