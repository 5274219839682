import { merge } from 'merge-anything';

import { files } from 'config';
import customEnvironmentVariables from 'config/custom-environment-variables';

export const config = merge(
    files.default,
    files[process.env.NODE_ENV as string] || {},
    files[`${process.env.NODE_ENV}-${process.env.DEPLOYMENT}`] || {},
    files.local || {},
    customEnvironmentVariables || {},
) as App.Config;
