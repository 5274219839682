import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { queryProducts, QUERY_KEY } from 'src/queries/queryProducts';

type Products = MCP.Products.ProductSearch.Models.ProductsResponse;

export const useQueryProducts = (
    accessToken: string | undefined | false,
    search: App.ProductSearch.PagedSearch,
    options?: UseQueryOptions<Products, Error, Products, App.ProductSearch.QueryKey>,
): UseQueryResult<Products, Error> => useQuery<Products, Error, Products, App.ProductSearch.QueryKey>(
    [QUERY_KEY, accessToken, search],
    queryProducts,
    options,
);
