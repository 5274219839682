import {
    Paper, PaperTypeMap, styled, TableRow, TableRowProps,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MouseEvent } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export interface PropTypes extends TableRowProps<OverridableComponent<PaperTypeMap<any, 'tr'>>> {
    to?: string;
}

const StyledTableRow = styled(TableRow)<PropTypes>(({ hover, theme }) => ({
    background: theme.palette.common.white,
    cursor: hover ? 'pointer' : 'default',
}));

export const CardRow = (props: PropTypes): JSX.Element => {
    const {
        children,
        onClick,
        to,
        ...rest
    } = props;

    const history = useNavigate();
    const navigate = (event: MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();

        if (to != null) {
            history(to);
        }
    };

    return (
        <StyledTableRow {...rest} component={Paper} onClick={to ? navigate : onClick}>
            {children}
        </StyledTableRow>
    );
};
