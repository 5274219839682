export const DEFAULT_LIMIT = 50;

export const DEFAULT_PRODUCT_SEARCH_LIMIT = 20;

export const DEFAULT_PRODUCT_VERSIONS_SEARCH_LIMIT = 10;

export const DEFAULT_STATUS = 500;

export const DEFAULT_CULTURE = 'en-ie';

export const SNACKBAR_TIMEOUT = 10000;

export const DPS_IDS_TIMEOUT = 120000;

export const GALLERY_CONFLUENCE_URL = 'https://vistaprint.atlassian.net/wiki/spaces/';

export const DEFAULT_ASSOCIATIONS_LIMIT = 20;

export const ASSOC_NAME_ERROR_TEXT = 'Association Name is required';
export const ASSOC_NAME_HELPER_TEXT = 'Provide a descriptive name for this product association';
export const MAX_VERSION_ERROR_TEXT = 'Cannot be less than Minimum Version';
export const MAX_VERSION_HELPER_DESCRIPTION = 'Select a product version from the dropdown to set the maximum version for which this association is used';
export const MAX_VERSION_HELPER_TEXT = 'Select a product version';
export const MIN_VERSION_ERROR_TEXT = 'Cannot be greater than Max Version';
export const MIN_VERSION_REQUIRED_TEXT = 'Min Product Version is required';
export const MIN_VERSION_HELPER_DESCRIPTION = 'Select a product version from the dropdown to set the minimum version for which this association is used';
export const MIN_VERSION_HELPER_TEXT = 'Select a product version';

export const DEFAULT_CONTENT_PANEL = 'Front';

export const DUCS_HELPER_TEXT = 'Use the text box to add new DUC IDs to the configuration. Click on any DUC from the list to delete it.';

export const PANEL_MAPPINGS_HELPER_TEXT = 'Use the content panel dropdown to update existing panel mappings. Click the delete icon to remove specific panel mappings. Click the create button to start adding a panel, then click the add button when to add it to the configuration.';
export const PANEL_RESTRICTIONS_HELPER_TEXT = 'This widget should only be used when ingesting content onto a product with fewer panels than the content (e.g. two sided content on a front-only product). Select the panels to be considered for matching.';

export const FINISHES_HELPER_TEXT = 'Finish mappings are based on product options. To change the product option, delete the current mapping and select a new one. There can be at most one finish mapping per configuration.';
export const FOLDS_HELPER_TEXT = 'Fold type mappings can be based on product options or a global fold type. For product option-specific fold types, select an option to create fold type mappings. The global mapping, when set, applies universally across the entire product, regardless of product options. There can be at most one product option mapping or one global mapping per configuration.';
export const VALID_TRANSPARENCY_OPTIONS = ['', 'metallic', 'raisedInk', 'raisedFoilGold', 'raisedFoilSilver', 'raisedFoilGlitterSilver', 'raisedFoilGlitter'];
export const CONENT_PANEL_OPTIONS = ['Front', 'Back', 'Inside'];

export const OPACITY_OPTIONS = ['opaque', 'transparent'];

export const FOLD_OPTIONS = ['Flat', 'BiFold', 'TriFold', 'ZFold'];

export const SHAPES_HELPER_TEXT = 'Shape mappings are based on product options. To change the product option, delete the current mapping and select a new one. There can be at most one shape mapping per configuration.';

export const LAUNCH_JOB_PERMISSION = 'manage:ingestjob';
export const EDIT_CONFIG_PERMISSION = 'manage:ingestconfig';

export const SUBSTRATE_COLOR_MAPPING_TEXT = 'Map the substrate colors provided by the DPSes made available by your DUC configuration to the appropraite substrate-related product option. Click the create button to input a custom RGB color, then click the add button to add it to the mappable substrate colors.';

export const MATCHER_VALIDATIONS_HELPER_TEXT = 'Enter a template token to check if it can be ingested by the PRD\'s current configuration. Use the filters to further refine your results by product options.';

export enum IngestJobStatus {
    Faulted = 'Faulted',
    Finished = 'Finished',
    Created = 'Created',
    InProgress = 'InProgress',
}

export enum Surface {
    Front = 'Front',
    Back = 'Back',
    Inside = 'Inside',
}

export enum MatcherType {
    RectangularExactFit = 'RectangularExactFit',
    RectangularFitsInside = 'RectangularFitsInside',
    IrregularShape = 'IrregularShape',
    SubstrateColor = 'SubstrateColor',
    Opacity = 'Opacity',
    DecoTech = 'DecoTech',
    Shape = 'Shape',
    Fold = 'Fold',
}

export const SURFACE_ENUMERATION = [
    [Surface.Front],
    [Surface.Front, Surface.Back],
    [Surface.Front, Surface.Inside],
    [Surface.Front, Surface.Inside, Surface.Back],
];

export enum UserEvent {
    ProductNameSearch = 'ProductNameSearch',
    ProductKeySearch = 'ProductKeySearch',
    PreviewGalleryButtonClick = 'PreviewGalleryButtonClick',
    ViewTaggingToolButtonClick = 'ViewTaggingToolButtonClick',
    MatcherValidationSearch = 'MatcherValidationSearch',
    VisualizationSearch = 'VisualizationSearch',
    WidgetClick = 'WidgetClick',
    DownloadMetadataReport = 'DownloadMetadataReport',
}
