import {
    Grid, styled, Typography,
} from '@mui/material';
import { ComponentProps } from 'react';

import { GhostSvg } from 'src/components/common/icons/GhostSvg';

const StyledGhostSvg = styled(GhostSvg)(({ theme }) => ({
    height: theme.spacing(20),
}));

const Wrapper = styled(Grid)(({ theme }) => ({
    paddingTop: theme.spacing(8),
}));

export const EmptyState = (props: ComponentProps<typeof Grid>): JSX.Element => {
    const { children, className, ...rest } = props;

    return (
        <Wrapper
            container
            alignItems="center"
            direction="column"
            justifyContent="center"
            spacing={2}
            {...rest}
        >
            <Grid item>
                <StyledGhostSvg />
            </Grid>
            <Grid item>
                <Typography>
                    {children}
                </Typography>
            </Grid>
        </Wrapper>
    );
};
