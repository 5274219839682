import {
    Grid, styled, Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { ComponentProps } from 'react';

const Wrapper = styled(Grid)(({ theme }) => ({
    paddingTop: theme.spacing(8),
    height: '75vh',
}));

const StyledSearch = styled(Search)(({ theme }) => ({
    height: theme.spacing(20),
    width: theme.spacing(20),
}));

export const ZeroSearchState = (props: ComponentProps<typeof Grid>): JSX.Element => (
    <Wrapper
        container
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={2}
        {...props}
    >
        <Grid item>
            <StyledSearch color="primary" />
        </Grid>
        <Grid item sx={{ textAlign: 'center' }}>
            <Typography fontWeight="medium" variant="h6">
                No results yet!
            </Typography>
            <Typography fontWeight="medium" variant="body1">
                Use the search bar to find what you&apos;re looking for
            </Typography>
        </Grid>
    </Wrapper>
);
