import { QueryFunctionContext } from '@tanstack/react-query';

import { DEFAULT_PRODUCT_SEARCH_LIMIT } from 'src/constants';
import { ProductSearchService } from 'src/services/ProductSearchService';

export const QUERY_KEY = 'products';

export const queryProducts = (
    context: QueryFunctionContext<App.ProductSearch.QueryKey, App.ProductSearch.PageParam>,
): Promise<MCP.Products.ProductSearch.Models.ProductsResponse> => {
    const { queryKey } = context;
    const [, accessToken, search] = queryKey;
    const page = search?.page || undefined;

    return ProductSearchService.getMatchingCurrentProducts(
        accessToken,
        search,
        page,
        DEFAULT_PRODUCT_SEARCH_LIMIT,
    );
};
