import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { DEFAULT_PRODUCT_VERSIONS_SEARCH_LIMIT } from 'src/constants';

const getMatchingCurrentProductsQuery = (
    productKey?: string,
    productName?: string,
    page?: number,
    limit?: number,
): MCP.Products.ProductSearch.Models.ProductQuery => {
    const searchQuery = [];

    if (productKey) {
        searchQuery.push({
            type: 'match',
            fieldName: 'productId',
            value: productKey,
        });
    }

    if (productName) {
        productName.split(' ').forEach((term) => {
            searchQuery.push({
                type: 'match',
                fieldName: 'productName',
                value: term,
            });
        });
    }

    return {
        page,
        pageSize: limit,
        query: {
            type: 'compound',
            operator: 'and',
            compoundQuery: [
                {
                    type: 'match',
                    fieldName: 'status',
                    value: 'ACTIVE',
                },
                {
                    type: 'compound',
                    operator: 'and',
                    compoundQuery: searchQuery,
                },
            ],
        },
        scroll: false,
        shouldAggregate: true,
    };
};

const getProductVersionQuery = (
    version?: number,
): MCP.Products.ProductSearch.Models.ProductVersionQuery => {
    const searchQuery = [];

    if (version) {
        searchQuery.push({
            type: 'match',
            fieldName: 'version',
            value: version.toString(),
        });
    }

    return {
        query: {
            type: 'compound',
            operator: 'and',
            compoundQuery: searchQuery,
        },
        sortOrder: [{
            fieldName: 'version',
            direction: 'desc',
        }],
        scroll: false,
        shouldAggregate: true,
    };
};

const getProductVersionsQuery = (
    page?: number,
    limit?: number,
): MCP.Products.ProductSearch.Models.ProductVersionQuery => ({
    page,
    pageSize: limit,
    query: {
        type: 'compound',
        operator: 'and',
        compoundQuery: [],
    },
    sortOrder: [{
        fieldName: 'version',
        direction: 'desc',
    }],
    scroll: false,
    shouldAggregate: true,
});

class ProductSearchServiceApi extends AbstractService implements MCP.Products.ProductSearch.Services.IProductSearchService {
    // Accept a product key or product name and search for matching current products
    public async getMatchingCurrentProducts(
        bearerToken: string | undefined | false,
        search?: App.ProductSearch.Search,
        page?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<MCP.Products.ProductSearch.Models.ProductsResponse> {
        if (search?.productKey?.length && search?.productName?.length) {
            throw new ServiceError({
                message: 'Unable to perform request getProductVesions. Cannot search by both product name and product key.',
            });
        }

        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getProductVesions. No bearerToken provided.',
            });
        }

        const url = 'v1/products/current:search';
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        const productKey = search?.productKey || undefined;
        const productName = search?.productName || undefined;

        try {
            const json = await this.api
                .post(
                    url,
                    {
                        json: getMatchingCurrentProductsQuery(productKey, productName, page, limit),
                        searchParams,
                        signal,
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    },
                ).json<MCP.Products.ProductSearch.Models.ProductsResponse>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch product versions',
                info: (e as Error).message,
                url,
                productName,
                productKey,
            });
        }
    }

    // Given a product key, retrieve information (all product versions) about a product
    public async getProductVersions(
        bearerToken: string | undefined | false,
        productKey: string,
        page?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<MCP.Products.ProductSearch.Models.ProductVersionsResponse> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getProductVesions. No bearerToken provided.',
            });
        }

        const url = `v1/products/${productKey}/versions:search`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .post(
                    url,
                    {
                        json: getProductVersionsQuery(page, limit || DEFAULT_PRODUCT_VERSIONS_SEARCH_LIMIT),
                        searchParams,
                        signal,
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    },
                )
                .json<MCP.Products.ProductSearch.Models.ProductVersionsResponse>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch product versions',
                info: (e as Error).message,
                url,
                productKey,
            });
        }
    }

    public async getProductVersion(
        bearerToken: string | undefined | false,
        productKey: string,
        version?: number,
        signal?: AbortSignal,
    ): Promise<MCP.Products.ProductSearch.Models.ProductVersionsResponse> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getProductVesion. No bearerToken provided.',
            });
        }

        const url = `v1/products/${productKey}/versions:search`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        const body = getProductVersionQuery(version);

        try {
            const json = await this.api
                .post(
                    url,
                    {
                        json: body,
                        searchParams,
                        signal,
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    },
                )
                .json<MCP.Products.ProductSearch.Models.ProductVersionsResponse>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch product version',
                info: (e as Error).message,
                url,
                productKey,
                body,
            });
        }
    }
}

export const ProductSearchService = new ProductSearchServiceApi(config.services.productSearchServiceApi);
