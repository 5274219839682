import {
    Box,
    styled, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';

import { PRODUCT_DETAILS } from 'src/lib/routes';
import { CardRow } from 'src/components/common/CardTable/CardRow';
import { CardCell } from 'src/components/common/CardTable/CardCell';
import { generatePath } from 'react-router-dom';

export interface PropTypes {
    data?: MCP.Products.ProductSearch.Models.Result[];
}

const Container = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(4),
}));

const StyledTable = styled(Table)(({ theme }) => ({
    borderSpacing: `0 ${theme.spacing(4)}`,
    tableLayout: 'fixed',
}));

export const SearchResultsList = (props: PropTypes): JSX.Element => {
    const {
        data,
    } = props;

    return (
        <Container>
            <StyledTable stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">STATUS</TableCell>
                        <TableCell align="center">PRODUCT KEY</TableCell>
                        <TableCell align="center" width="40%">PRODUCT NAME</TableCell>
                        <TableCell align="center">CURRENT VERSION</TableCell>
                        <TableCell align="center">MODIFIED DATE</TableCell>
                        <TableCell align="center">MODIFIED BY</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!data && !!data[0].matching_versions.length
                    && data.map((productVersion): JSX.Element => (
                        <CardRow
                            hover
                            key={productVersion.matching_versions[0].productId}
                            to={generatePath(PRODUCT_DETAILS, {
                                productKey: productVersion.matching_versions[0].productId.toString(),
                            })}
                        >
                            <CardCell align="center">
                                {productVersion.matching_versions[0].status || 'None'}
                            </CardCell>
                            <CardCell align="center">
                                {productVersion.matching_versions[0].productId}
                            </CardCell>
                            <CardCell align="center">
                                {productVersion.matching_versions[0].productName}
                            </CardCell>
                            <CardCell align="center">
                                {productVersion.matching_versions[0].version}
                            </CardCell>
                            <CardCell align="center">
                                {new Date(productVersion.matching_versions[0].auditInfo?.modifiedAt)
                                    .toLocaleDateString()}
                            </CardCell>
                            <CardCell align="center">
                                {productVersion.matching_versions[0].auditInfo.modifiedBy}
                            </CardCell>
                        </CardRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Container>
    );
};
