import {
    ExtendButtonBase, styled, Tab, TabProps, TabTypeMap,
} from '@mui/material';
import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';

export type PropTypes = Omit<ComponentProps<typeof Tab>, 'component' | 'to'> & {
    to: string
};

type LinkTab = ExtendButtonBase<TabTypeMap<TabProps<typeof Link>, typeof Link>>;

const StyledTab = styled(Tab)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.action.hover,
    },
})) as LinkTab;

export const NavTab = (props: PropTypes): JSX.Element => {
    const {
        label,
        to,
        ...rest
    } = props;

    return (
        <StyledTab
            {...rest as TabProps<typeof Link>}
            component={Link}
            label={label}
            to={to}
            value={to}
        />
    );
};
