import {
    styled, TableCell, TableCellProps,
} from '@mui/material';

const StyledTableCell = styled(TableCell)(() => ({
    maxWidth: 'auto', // percentage also works
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

interface PropTypes extends TableCellProps {
    to?: string;
}

export const CardCell = (props: PropTypes): JSX.Element => {
    const {
        children,
        onClick,
        to,
        ...rest
    } = props;

    return (
        <StyledTableCell {...rest}>
            {children}
        </StyledTableCell>
    );
};
