import { Refresh } from '@mui/icons-material';
import {
    Button, CircularProgress, Grid, styled, Typography,
} from '@mui/material';
import { ReactNode, MouseEvent, ComponentProps } from 'react';

import { AppError } from 'src/lib/errors';
import { getErrorMessageTitle, getFormatedErrorMessage } from 'src/lib/errors/humanReadableErrorUtils';

const Wrapper = styled(Grid)(({ theme }) => ({
    paddingTop: theme.spacing(8),
}));

const Message = styled(Typography)({
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: '100%',
});

interface PropTypes extends Omit<ComponentProps<typeof Grid>, 'onReset'> {
    children?: ReactNode;
    error: AppError | Error;
    onReset?: (event: MouseEvent<HTMLButtonElement>) => void;
    isLoading?: boolean;
    headerProps?: ComponentProps<typeof Typography>;
    subheaderProps?: ComponentProps<typeof Typography>;
}

export const ResetableErrorStateMessage = (props: PropTypes): JSX.Element => {
    const {
        children,
        error,
        onReset,
        isLoading,
        headerProps,
        subheaderProps,
        ...rest
    } = props;

    return (
        <Wrapper
            container
            alignItems="center"
            direction="column"
            justifyContent="center"
            {...rest}
        >
            <Grid item>
                <Typography gutterBottom align="center" {...headerProps}>
                    Whoops!
                </Typography>
            </Grid>
            <Typography textAlign="center" variant="body1">
                {getErrorMessageTitle(error)}
            </Typography>
            {children && (
                <Typography gutterBottom align="center" {...subheaderProps}>
                    {children}
                </Typography>
            )}
            <Grid item>
                {('guid' in error) && (
                    <Typography
                        paragraph
                        align="center"
                        variant="body1"
                    >
                        {error.guid}
                    </Typography>
                )}
                <Message
                    align="center"
                    as="pre"
                    variant="body1"
                >
                    {getFormatedErrorMessage(error)}
                </Message>
                {process.env.DEPLOYMENT !== 'development' && (
                    <Message
                        align="center"
                        as="pre"
                        maxWidth="100%"
                        variant="body1"
                    >
                        {error.stack}
                    </Message>
                )}
            </Grid>
            {onReset && (
                <Grid item>
                    <Button
                        color="primary"
                        disabled={isLoading}
                        startIcon={isLoading ? <CircularProgress size={20} /> : <Refresh />}
                        variant="outlined"
                        onClick={onReset}
                    >
                        Retry
                    </Button>
                </Grid>
            )}
        </Wrapper>
    );
};
