import { ErrorBoundary as ReactErrorBoundary, ErrorBoundaryPropsWithRender, FallbackProps } from 'react-error-boundary';
import { ReactNode } from 'react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';

import { ErrorPage } from 'src/pages/ErrorPage';
import { getLogger } from 'src/lib/logger';
import { AppError } from 'src/lib/errors';

export type PropTypes = Omit<ErrorBoundaryPropsWithRender, 'fallbackRender'> & {
    children?: ReactNode;
    fallbackRender?: ErrorBoundaryPropsWithRender['fallbackRender'];
}

const LOGGER = getLogger();

const defaultFallbackRender = ({ error: e, resetErrorBoundary }: FallbackProps): JSX.Element => (
    <ErrorPage error={e} onReset={resetErrorBoundary} />
);

export const ErrorBoundary = (props: PropTypes): JSX.Element => {
    const {
        children,
        fallbackRender = defaultFallbackRender,
        fallback,
        ...rest
    } = props;
    const { reset } = useQueryErrorResetBoundary();

    return (
        <ReactErrorBoundary
            onError={(e): Promise<null> => LOGGER.error(new AppError(e))}
            {...rest}
            fallbackRender={fallbackRender}
            onReset={reset}
        >
            {children}
        </ReactErrorBoundary>
    );
};
