import {
    Grid,
    styled,
} from '@mui/material';
import { ComponentProps, ReactNode, MouseEvent } from 'react';

import { AppError } from 'src/lib/errors';
import { ResetableErrorStateMessage } from 'src/components/common/ResetableErrorStateMessage';

export interface PropTypes extends ComponentProps<typeof Grid> {
    children?: ReactNode;
    error: AppError | Error;
    onReset: (...args: unknown[]) => void;
}

const StyledErrorDisplayMessage = styled(ResetableErrorStateMessage)(({ theme }) => ({
    gridArea: 'content',
    margin: 0,
    paddingTop: theme.spacing(8),
    width: '100%',
}));

export const ErrorPage = (props: PropTypes): JSX.Element => {
    const { children, error, onReset } = props;

    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (onReset) {
            onReset();
        }
    };

    return (
        <StyledErrorDisplayMessage
            error={error}
            headerProps={{ variant: 'h2' }}
            rowGap={10}
            subheaderProps={{ variant: 'h3' }}
            onReset={handleClick}
        >
            {children}
        </StyledErrorDisplayMessage>
    );
};
