import { Save } from '@mui/icons-material';
import { ComponentProps } from 'react';

import { LoadingButton } from 'src/components/common/buttons/LoadingButton';

export const SaveLoadingButton = (props: ComponentProps<typeof LoadingButton>): JSX.Element => {
    const {
        Icon = Save,
        ...rest
    } = props;

    return (
        <LoadingButton {...rest} aria-label="save changes" Icon={Icon} type="submit" />
    );
};
