import { useRecoilValue } from 'recoil';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import {
    UseMutateFunction,
    useMutation,
} from '@tanstack/react-query';
import { RiverService } from 'src/services/RiverService';
import { ServiceError } from 'src/lib/errors';

interface TrackEventParams {
    eventName: string;
    eventValue?: string;
}

export const useTrackEvent = (): { trackEvent: UseMutateFunction<void, ServiceError, TrackEventParams, unknown> } => {
    const accessToken = useRecoilValue(isAuthorizedQuery);

    const { mutate: trackEvent } = useMutation<void, ServiceError, TrackEventParams>(
        async ({ eventName, eventValue }: TrackEventParams) => {
            await RiverService.trackEvent(accessToken, { eventName, eventValue });
        },
    );

    return { trackEvent };
};
