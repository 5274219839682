import {
    CircularProgress, styled, Typography,
} from '@mui/material';
import { ComponentProps } from 'react';

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    marginRight: theme.spacing(3),
}));

const Wrapper = styled(Typography)({
    gridArea: 'content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const Loader = (props: ComponentProps<typeof Typography>): JSX.Element => (
    <Wrapper {...props}>
        <StyledCircularProgress />
    </Wrapper>
);
