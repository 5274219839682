import { createRoot } from 'react-dom/client';
import { App } from 'src/App';
import { getLogger } from 'src/lib/logger';

try {
    const el = document.getElementById('app');

    if (!el) {
        throw Error('Unable to render: target DOM element does not exist');
    }

    const root = createRoot(el);

    root.render(<App />);
} catch (e) {
    // TODO show something
    getLogger().error(e as Error);
}
