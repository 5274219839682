import { useParams } from 'react-router-dom';
import { styled } from '@mui/material';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { TitledAppBar } from 'src/components/common/TitledAppBar';
import { ProductAssociationsListSkeleton } from 'src/components/ProductDetails/ProductAssociationsPanel/ProductAssociationsListSkeleton';
import { ProductDetailsNavBar } from 'src/components/ProductDetails/ProductDetailsNavBar';

const StyledCSSGrid = styled(CSSGrid)(({ theme }) => ({
    minHeight: '100%',
    gap: theme.spacing(6),
    gridArea: 'content',
    gridTemplateAreas: '"nav outlet"',
    paddingRight: theme.spacing(6),
}));

export const ProductDetailsSkeleton = (): JSX.Element => {
    const { productKey } = useParams();

    return (
        <>
            <TitledAppBar title={productKey} />
            <StyledCSSGrid>
                <ProductDetailsNavBar disabled />
                <ProductAssociationsListSkeleton />
            </StyledCSSGrid>
        </>
    );
};

// eslint-disable-next-line import/no-default-export
export default ProductDetailsSkeleton;
