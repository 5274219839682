import qs from 'qs';
import { ChangeEvent } from 'react';
import { SearchResultsList } from 'src/components/ProductSearchPage/SearchResultsList';
import { useQueryProducts } from 'src/components/ProductSearchPage/hooks/useQueryProducts';
import { isSearchPopulated } from 'src/components/ProductSearchPage/util';
import { DEFAULT_PRODUCT_SEARCH_LIMIT } from 'src/constants';
import { EmptyState } from 'src/components/common/EmptyState';
import { ZeroSearchState } from 'src/components/common/ZeroSearchState';
import { Pagination, styled } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { useLocation, useNavigate } from 'react-router-dom';

export type PropTypes = {
    search?: App.ProductSearch.PagedSearch;
};

const PaginationContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#f5f6f7',
    width: `calc(100% + ${theme.spacing(2)})`,
    transform: `translateX(${theme.spacing(-1)})`,
    position: 'sticky',
    left: '0',
    padding: theme.spacing(4),
    bottom: theme.spacing(-3),
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
    margin: `${theme.spacing(4)} auto`,
    alignContent: 'center',
    width: 'fit-content',
}));

export const SearchResults = (props: PropTypes): JSX.Element => {
    const { search } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const page = search?.page || 1;
    const {
        data,
    } = useQueryProducts(accessToken, { ...search, page }, {
        enabled: (!!search?.productName || !!search?.productKey) && !!accessToken,
    });

    const handlePaginationChange = (event: ChangeEvent<unknown>, value: number): void => {
        event.preventDefault();
        navigate({
            ...location,
            search: qs.stringify({ ...search, page: value > 1 ? value : undefined }),
        });
    };

    return (
        <>
            {!isSearchPopulated(search) && (<ZeroSearchState />)}
            {isSearchPopulated(search) && !data?.productsCount && (
                <EmptyState>
                    Could not find products for the given parameters
                </EmptyState>
            )}
            {!!data?.result && data?.productsCount > 0 && (
                <SearchResultsList data={data.result || []} />
            )}
            {data?.result && data.productsCount > DEFAULT_PRODUCT_SEARCH_LIMIT && (
                <PaginationContainer>
                    <StyledPagination
                        color="primary"
                        count={Math.ceil(data.productsCount / DEFAULT_PRODUCT_SEARCH_LIMIT)}
                        page={page}
                        onChange={handlePaginationChange}
                    />
                </PaginationContainer>
            )}
        </>
    );
};
