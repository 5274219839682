import { Suspense, lazy } from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
    CssBaseline,
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material';
import { dttVisageInternalTheme } from '@vp/themes/packages/material-ui@v5';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
import { Head } from 'src/components/Head';
import { Splash } from 'src/components/common/Splash';
import { ErrorBoundary } from 'src/components/common/ErrorBoundary';
import { config } from 'src/lib/config';
import { AuthBoundary } from 'src/components/AuthBoundary';
import { Layout } from 'src/components/common/Layout';
import { Logout } from 'src/pages/Logout';
import { Loader } from 'src/components/common/Loader';
import { ProductSearchPage } from 'src/pages/ProductSearchPage';
import { ProductDetailsSkeleton } from 'src/components/ProductDetails/ProductDetailsSkeleton';
import * as routes from 'src/lib/routes';

const ProductDetailsPage = lazy(() => import(/* webpackPrefetch: true */'src/pages/ProductDetailsPage'));
const ProductAssociationConfigurationPage = lazy(() => import('src/pages/ProductAssociationConfigurationPage'));
const IngestJobDetailsPage = lazy(() => import('src/pages/IngestJobDetailsPage'));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
        },
    },
});

broadcastQueryClient({
    queryClient,
    broadcastChannel: `gallery-ingest-configuration-tool-${process.env.DEPLOYMENT}`,
});

// For some reason, the divider color in the theme is white
// I'm not sure why and instead of changing the theme and regressing everything using the theme,
// we'll override divider to be black globally here
dttVisageInternalTheme.palette.divider = dttVisageInternalTheme.palette.action.focus;

const icsTheme = {
    ...dttVisageInternalTheme,
    components: {
        ...dttVisageInternalTheme.components,
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    borderRadius: dttVisageInternalTheme.spacing(1),
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: dttVisageInternalTheme.palette.grey['300'],
                },
            },
        },
    },
};

// const router = createBrowserRouter(
//     createRoutesFromElements(
//         <Route element={<Layout />} path={routes.ROOT}>
//             <Route element={<Logout />} path={routes.LOGOUT} />
//             <Route
//                 index
//                 element={<ProductSearchPage />}
//             />
//             <Route
//                 element={(
//                     <Suspense fallback={<ProductDetailsSkeleton />}>
//                         <ProductDetailsPage />
//                     </Suspense>
//                 )}
//                 path={`${routes.PRODUCT_DETAILS}/*`}
//             />
//             <Route
//                 element={(
//                     <Suspense fallback={<Loader />}>
//                         <ProductAssociationConfigurationPage />
//                     </Suspense>
//                 )}
//                 path={routes.PRODUCT_ASSOCIATION_CONFIG}
//             />
//             <Route
//                 element={(
//                     <Suspense fallback={<Loader />}>
//                         <IngestJobDetailsPage />
//                     </Suspense>
//                 )}
//                 path={routes.INGEST_JOB_DETAILS}
//             />
//         </Route>,
//     ),
//     {
//         basename: config.basename,
//     },
// );

export const App = (): JSX.Element => (
    <RecoilRoot>
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
                <ThemeProvider theme={icsTheme}>
                    <StyledEngineProvider injectFirst>
                        <Head />
                        <CssBaseline />
                        <ErrorBoundary>
                            <Suspense fallback={<Splash />}>
                                <AuthBoundary>
                                    {/* <RouterProvider router={router} /> */}
                                    <BrowserRouter basename={config.basename}>
                                        <Routes>
                                            <Route element={<Layout />} path={routes.ROOT}>
                                                <Route element={<Logout />} path={routes.LOGOUT} />
                                                <Route index element={<ProductSearchPage />} />
                                                <Route
                                                    element={(
                                                        <Suspense fallback={<ProductDetailsSkeleton />}>
                                                            <ProductDetailsPage />
                                                        </Suspense>
                                                    )}
                                                    path={`${routes.PRODUCT_DETAILS}/*`}
                                                />
                                                <Route
                                                    element={(
                                                        <Suspense fallback={<Loader />}>
                                                            <ProductAssociationConfigurationPage />
                                                        </Suspense>
                                                    )}
                                                    path={routes.PRODUCT_ASSOCIATION_CONFIG}
                                                />
                                                <Route
                                                    element={(
                                                        <Suspense fallback={<Loader />}>
                                                            <IngestJobDetailsPage />
                                                        </Suspense>
                                                    )}
                                                    path={routes.INGEST_JOB_DETAILS}
                                                />
                                            </Route>
                                        </Routes>
                                    </BrowserRouter>
                                </AuthBoundary>
                            </Suspense>
                        </ErrorBoundary>
                    </StyledEngineProvider>
                </ThemeProvider>
                <ReactQueryDevtools />
            </ErrorBoundary>
        </QueryClientProvider>
    </RecoilRoot>
);
