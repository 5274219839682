import { Box, styled, Tabs } from '@mui/material';
import { ComponentProps, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { NavTab, PropTypes as NavTabProps } from 'src/components/common/NavBar/NavTab';

export type { NavTabProps };

export type PropTypes = Omit<ComponentProps<typeof Box>, 'children'> & {
    'aria-label': string;
    disabled?: boolean;
    tabs: ComponentProps<typeof NavTab>[];
    orientation?: ComponentProps<typeof Tabs>['orientation'];
    tabProps?: Omit<ComponentProps<typeof Tabs>, 'orientation' | 'variant'>;
    variant?: ComponentProps<typeof Tabs>['variant'];
};

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    gridArea: 'nav',
    marginTop: '-14px',
    zIndex: 1101,
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
    width: theme.spacing(36),
}));

const useActiveRoute = (patterns: string[]): string | undefined => {
    const location = useLocation();

    return useMemo(() => patterns.reduce(
        // If pattern is an empty string, then technically it always matches; otherwise,
        // we only really case if the pattern appears at the end of the current url
        (accum: string | undefined, pattern) => (location.pathname.endsWith(pattern) ? pattern : accum),
        undefined,
    ), [location.pathname, patterns]);
};

export const NavBar = (props: PropTypes): JSX.Element => {
    const {
        disabled,
        tabs,
        orientation = 'vertical',
        tabProps = {},
        variant = 'scrollable',
        ...rest
    } = props;
    const patterns = useMemo(() => tabs.map((tab) => tab.to), [tabs]);
    const activeRoute = useActiveRoute(patterns);

    return (
        <StyledBox {...rest}>
            <StyledTabs
                {...tabProps}
                orientation={orientation}
                value={activeRoute}
                variant={variant}
            >
                {tabs.map((tabProp) => (<NavTab disabled={disabled} {...tabProp} />))}
            </StyledTabs>
        </StyledBox>
    );
};
