import { Grid, styled } from '@mui/material';
import { ComponentProps } from 'react';

import { LogoIcon } from 'src/components/common/icons/Logo';

const StyledLogoIcon = styled(LogoIcon)({
    display: 'inline-block',
    verticalAlign: 'middle',
});

export const Splash = (props: ComponentProps<typeof Grid>): JSX.Element => (
    <Grid
        container
        item
        alignItems="center"
        justifyContent="center"
        sx={{
            height: '100vh',
            textAlign: 'center',
        }}
        xs={12}
        {...props}
    >
        <Grid item xs={4}>
            <StyledLogoIcon size="xxl" />
        </Grid>
    </Grid>
);
