import { ServiceError } from 'src/lib/errors/ServiceError';

export type ValidationErrorPayload = App.Error.Payload & {
    validation: Models.ValidationPayload;
};

export class ValidationError extends ServiceError {
    public validation: Models.ValidationPayload;

    constructor(payload: ValidationErrorPayload, error?: Error) {
        super({
            status: 400,
            stack: error?.stack,
            ...error,
            ...payload,
        });

        this.name = 'ValidationError';
        this.validation = payload.validation;
    }
}
