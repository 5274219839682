import { Box, useTheme } from '@mui/material';
import { OverridableComponent, OverrideProps } from '@mui/material/OverridableComponent';
import { SystemProps } from '@mui/system';
import { ComponentProps, ElementType, forwardRef } from 'react';

export interface CSSGridTypeMap<P = Record<string, unknown>, D extends ElementType = 'div'> {
    props: P & SystemProps & Omit<ComponentProps<typeof Box>, 'onChange'> & {
        alignItems?: string;
        children?: React.ReactNode;
        gap?: number;
        gridTemplateAreas?: string;
        gridTemplateColumns?: string;
        justifyContent?: string;
    };
    defaultComponent: D;
}

export type CSSGridProps<
    D extends ElementType = CSSGridTypeMap['defaultComponent'],
    P = Record<string, unknown>
    > = OverrideProps<CSSGridTypeMap<P, D>, D>;

// @ts-ignore
export const CSSGrid: OverridableComponent<CSSGridTypeMap> = forwardRef((props: CSSGridProps, ref): JSX.Element => {
    const { component, sx, ...rest } = props;
    const theme = useTheme();
    const Component = (component || 'div') as ElementType;

    return (
        <Box
            {...rest}
            component={Component}
            ref={ref}
            sx={{
                alignItems: props.alignItems || 'unset',
                display: 'grid',
                gap: props.gap != null ? theme.spacing(props.gap) : undefined,
                gridTemplateAreas: props.gridTemplateAreas || undefined,
                gridTemplateColumns: props.gridTemplateColumns || undefined,
                justifyContent: props.justifyContent || undefined,
                ...sx as Record<string, unknown>,
            }}
        />
    );
});
