import { Help } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { GALLERY_CONFLUENCE_URL } from 'src/constants';

const href = `${GALLERY_CONFLUENCE_URL}GAL/pages/2579858489/Gallery+Ingest+Tooling+User+Guide`;

export const HelpLink = (): JSX.Element => (
    <Tooltip title="Click to open tooling documentation">
        <IconButton
            aria-label="documentation"
            color="primary"
            component="a"
            href={href}
            size="small"
            target="_blank"
        >
            <Help fontSize="small" />
        </IconButton>
    </Tooltip>
);
