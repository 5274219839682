import qs from 'qs';

export const getQueryStringValues = (): App.ProductSearch.PagedSearch | undefined => {
    const values = qs.parse(window.location.search.slice(1));

    return {
        productName: values.productName as string || undefined,
        productKey: values.productKey as string || null,
        page: parseInt(values.page as string, 10) || undefined,
    };
};

export const getValidationQueryStringValues = (): App.IngestValidations.Search | undefined => {
    const values = qs.parse(window.location.search.slice(1));

    return {
        templateTokens: values.templateTokens as string[] || undefined,
        productVersion: values.productVersion ? parseInt(values.productVersion as string, 10) || undefined : undefined,
        excludeMatches: values.excludeMatches !== undefined ? values.excludeMatches === 'true' : false,
        productOptions: values.productOptions as App.IngestValidations.ProductOptions || undefined,
    };
};

export const getVisualizationQueryStringValues = (): App.IngestVisualizations.Search | undefined => {
    const values = qs.parse(window.location.search.slice(1));

    return {
        templateToken: values.templateToken as string || undefined,
        productVersion: values.productVersion ? parseInt(values.productVersion as string, 10) || undefined : undefined,
        productOptions: values.productOptions as App.IngestVisualizations.ProductOptions || undefined,
    };
};
