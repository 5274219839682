import {
    AppBar,
    styled,
    Typography,
    Toolbar,
    Divider,
    Tooltip,
} from '@mui/material';
import { ComponentProps, ReactElement } from 'react';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { LogoIcon } from 'src/components/common/icons/Logo';
import { HelpLink } from 'src/components/common/HelpLink';
import { Breadcrumbs } from 'src/components/common/Breadcrumbs';
import { Link } from 'react-router-dom';

export type PropTypes = Omit<ComponentProps<typeof AppBar>, 'title'> & {
    title?: ReactElement | string;
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    gridArea: 'header',
    minHeight: theme.spacing(16),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    zIndex: 500,
}));

const StyledCssGrid = styled(CSSGrid)(({ theme }) => ({
    width: '100%',
    gridAutoFlow: 'row',
    gridTemplateAreas: '". . . . ."',
    gridTemplateColumns: 'auto 1fr auto auto',
    alignItems: 'center',
    gap: theme.spacing(4),
    paddingBottom: theme.spacing(2),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
}));

const StyledToolbar = styled(Toolbar)({
    display: 'grid',
    gridAutoFlow: 'row',
});

export const TitledAppBar = (props: PropTypes): JSX.Element => {
    const { children, title, ...rest } = props;

    return (
        <StyledAppBar color="inherit" position="sticky" {...rest}>
            <StyledToolbar>
                <StyledCssGrid gap={3}>
                    <Tooltip title="Go to product search page">
                        <Link aria-label="Go to product search page" to="/">
                            <LogoIcon size="sm" />
                        </Link>
                    </Tooltip>
                    <StyledTypography variant="h6">
                        {title}
                    </StyledTypography>
                    {children}
                    <HelpLink />
                </StyledCssGrid>
                <Divider />
                <Breadcrumbs />
            </StyledToolbar>
        </StyledAppBar>
    );
};
