import {
    styled, Skeleton, Table, TableBody, Button, TextField, Grid,
} from '@mui/material';

import { CardRow } from 'src/components/common/CardTable/CardRow';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { Box } from '@mui/system';
import { Add } from '@mui/icons-material';
import { CancelLoadingButton } from 'src/components/common/buttons/CancelLoadingButton';
import { SaveLoadingButton } from 'src/components/common/buttons/SaveLoadingButton';

const PLACEHOLDERS: number[] = Array.from({ length: 6 }, (_, i) => i + 1);

const SpacerRow = styled('tr')(({ theme }) => ({
    height: theme.spacing(4),
}));

const StyledCSSGrid = styled(CSSGrid)(({ theme }) => ({
    minHeight: '100%',
    gap: theme.spacing(3),
    gridArea: 'outlet',
    gridTemplateAreas: `
        "actions"
        "results"
        "pagination"
    `,
    gridTemplateRows: 'auto 1fr auto',
}));

const ActionsCSSGrid = styled(CSSGrid)(({ theme }) => ({
    gap: theme.spacing(2),
    gridArea: 'actions',
    justifyContent: 'right',
}));

const StyledTable = styled(Table)(({ theme }) => ({
    borderSpacing: `0 ${theme.spacing(4)}`,
    tableLayout: 'fixed',
}));

const TileActionsCSSGrid = styled(CSSGrid)(({ theme }) => ({
    gridArea: 'actions',
    gridGap: theme.spacing(4),
    justifyContent: 'flex-end',
    gridAutoFlow: 'column dense',
}));

const StyledCssGrid = styled(CSSGrid)(({ theme }) => ({
    gridTemplateAreas: `
        "form"
        "actions"
    `,
    gap: theme.spacing(6),
    padding: theme.spacing(6),
}));

export const ProductAssociationsListSkeleton = (): JSX.Element => (
    <StyledCSSGrid>
        <ActionsCSSGrid>
            <Skeleton>
                <Button
                    startIcon={(<Add />)}
                    type="submit"
                    variant="contained"
                >
                    New Association
                </Button>
            </Skeleton>
        </ActionsCSSGrid>
        <Box>
            <StyledTable>
                <TableBody>
                    {PLACEHOLDERS.map((item) => (
                        <>
                            <CardRow key={item}>
                                <StyledCssGrid>
                                    <Grid container height="fit-content" spacing={8}>
                                        <Grid item xs={3}>
                                            <Skeleton animation="wave" variant="rectangular" width="100%">
                                                <TextField variant="standard" />
                                            </Skeleton>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Skeleton animation="wave" variant="rectangular" width="100%">
                                                <TextField variant="standard" />
                                            </Skeleton>
                                        </Grid>
                                        <Grid container item spacing={2} xs={4}>
                                            <Grid item xs={4}>
                                                <Skeleton animation="wave" variant="rectangular" width="100%">
                                                    <Button>Edit Config</Button>
                                                </Skeleton>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Skeleton animation="wave" variant="rectangular" width="100%">
                                                    <Button>Clone</Button>
                                                </Skeleton>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Skeleton animation="wave" variant="rectangular" width="100%">
                                                    <Button>Delete</Button>
                                                </Skeleton>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Skeleton animation="wave" variant="rectangular" width="100%">
                                                <TextField />
                                            </Skeleton>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Skeleton animation="wave" variant="rectangular" width="100%">
                                                <TextField />
                                            </Skeleton>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Skeleton animation="wave" variant="rectangular" width="100%">
                                                <TextField />
                                            </Skeleton>
                                        </Grid>
                                    </Grid>
                                    <TileActionsCSSGrid justifyContent="flex-end">
                                        <CancelLoadingButton disabled>
                                            Cancel
                                        </CancelLoadingButton>
                                        <SaveLoadingButton disabled>
                                            Save
                                        </SaveLoadingButton>
                                    </TileActionsCSSGrid>
                                </StyledCssGrid>
                            </CardRow>
                            <SpacerRow />
                        </>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    </StyledCSSGrid>
);
