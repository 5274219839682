import {
    Button,
    Grid,
    styled,
    Typography,
} from '@mui/material';
import { ComponentProps } from 'react';

const Page = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    height: '100',
    textAlign: 'center',
}));

const triggerReload = (): void => {
    window.location.reload();
};

export const UnauthorizedPage = (props: ComponentProps<typeof Grid>): JSX.Element => (
    <Page
        container
        item
        alignItems="center"
        justifyContent="center"
        xs={12}
        {...props}
    >
        <Grid item xs={4}>
            <Typography paragraph variant="h2">401</Typography>
            <Typography paragraph variant="h6">Unauthorized</Typography>
            <Button color="primary" variant="contained" onClick={triggerReload}>Log in again</Button>
        </Grid>
    </Page>
);
