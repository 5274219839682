import { Helmet } from 'react-helmet';

export const Head = (): JSX.Element => (
    <Helmet>
        <meta charSet="UTF-8" />
        <meta content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" name="viewport" />
        <meta content="telephone=no" name="format-detection" />
        <meta content="ie=edge" httpEquiv="X-UA-Compatible" />
        <meta content="#006196" name="theme-color" />
        <meta content="noindex, nofollow" name="robots" />
        <meta content="strict-origin-when-cross-origin" name="referrer" />

        <title>Gallery Ingest Configuration Tool</title>

        <link as="font" crossOrigin="anonymous" href="https://ui-library.cdn.vpsvc.com/fonts/Graphik-Bold-Web.woff2" rel="preload" />
        <link as="font" crossOrigin="anonymous" href="https://ui-library.cdn.vpsvc.com/fonts/Graphik-Medium-Web.woff2" rel="preload" />
        <link as="font" crossOrigin="anonymous" href="https://ui-library.cdn.vpsvc.com/fonts/Graphik-Regular-Web.woff2" rel="preload" />

        <link href="/images/ingest-favicon.png" rel="icon" />
        <link href="https://ui-library.cdn.vpsvc.com/images/favicon/vistaprint-favorites-76-76-2014-2x.png" rel="apple-touch-icon" sizes="152x152" />
        <link href="https://ui-library.cdn.vpsvc.com/images/favicon/vistaprint-favorites-60-60-2014-2x.png" rel="apple-touch-icon" sizes="120x120" />
        <link href="https://ui-library.cdn.vpsvc.com/images/favicon/vistaprint-favorites-76-76-2014.png" rel="apple-touch-icon" sizes="76x76" />
        <link href="https://ui-library.cdn.vpsvc.com/images/favicon/vistaprint-favorites-60-60-2014.png" rel="apple-touch-icon" sizes="60x60" />

        {process.env.DEPLOYMENT === 'qa' && (
            <script
                data-merge-request-id={process.env.CI_MERGE_REQUEST_IID || ''}
                data-mr-url="https://gitlab.com"
                data-project-id={process.env.CI_PROJECT_ID || ''}
                data-project-path={process.env.CI_PROJECT_PATH || ''}
                id="review-app-toolbar-script"
                src="https://gitlab.com/assets/webpack/visual_review_toolbar.js"
            />
        )}

        <style>
            {`
                @font-face {
                    font-family: "Graphik";
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: 900;
                    src: url("https://ui-library.cdn.vpsvc.com/fonts/Graphik-Bold-Web.woff2") format("woff2"), url("https://ui-library.cdn.vpsvc.com/fonts/Graphik-Bold-Web.woff") format("woff");
                }

                @font-face {
                    font-family: "Graphik";
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: 700;
                    src: url("https://ui-library.cdn.vpsvc.com/fonts/Graphik-Medium-Web.woff2") format("woff2"), url("https://ui-library.cdn.vpsvc.com/fonts/Graphik-Medium-Web.woff") format("woff");
                }

                @font-face {
                    font-family: "Graphik";
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: 400;
                    src: url("https://ui-library.cdn.vpsvc.com/fonts/Graphik-Regular-Web.woff2") format("woff2"), url("https://ui-library.cdn.vpsvc.com/fonts/Graphik-Regular-Web.woff") format("woff");
                }
            `}
        </style>

        <style type="text/css">
            {`
                html, body, #app {
                    height: 100%;
                }

                #app {
                    display: flex;
                    overflow: hidden;
                }
            `}
        </style>
    </Helmet>
);
