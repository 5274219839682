import {
    Button, ButtonProps, styled, Tooltip,
} from '@mui/material';
import {
    ComponentProps, ElementType, MouseEvent,
} from 'react';

export type PropTypes<E extends ElementType, P> = Omit<ButtonProps<E, P>, 'pending' | 'onClick'> & ComponentProps<E> & {
    onClick?: (event: MouseEvent<HTMLButtonElement>, abort?: AbortController) => (Promise<void> | void);
};

const StyledTitledButton = styled(Button)(({ theme }) => ({
    minWidth: 'auto',
    padding: theme.spacing(3),
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .MuiButton-startIcon': {
        margin: 0,
    },
}));

export const TitledButton = <E extends ElementType, P>(props: PropTypes<E, P>): JSX.Element => {
    const {
        children,
        variant = 'outlined',
        title,
        ...rest
    } = props;
    const Component = children ? Button : StyledTitledButton;

    return (
        <Tooltip title={rest['aria-label'] || title || ''}>
            <Component
                {...rest}
                variant={children ? 'outlined' : variant}
            >
                {children}
            </Component>
        </Tooltip>
    );
};
