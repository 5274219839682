// eslint-disable-next-line import/no-default-export
export default {
    basename: process.env.BASENAME,
    rollbar: {
        notifier: {
            payload: {
                environment: process.env.DEPLOYMENT,
                client: {
                    javascript: {
                        code_version: process.env.VERSION,
                    },
                },
            },
        },
    },
};
