import React, { useState } from 'react';
import {
    TextField,
    Grid,
    Button,
    styled,
    PaperProps,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { generatePath, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { PRODUCT_DETAILS } from 'src/lib/routes';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { useQueryProducts } from 'src/components/ProductSearchPage/hooks/useQueryProducts';
import { useTrackEvent } from 'src/hooks/useTrackEvent';
import { UserEvent } from 'src/constants';

interface PropTypes extends Omit<PaperProps, 'onSubmit'> {
    onSubmit: (search: App.ProductSearch.Search) => void;
    search: App.ProductSearch.Search;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    margin: '0 0',
    minWidth: theme.spacing(65),
}));

const StyledGrid = styled(Grid)(() => ({
    alignItems: 'center',
}));

const StyledForm = styled('form')(({ theme }) => ({
    margin: `${theme.spacing(4)} auto`,
    alignContent: 'center',
    width: '100%',
}));

export const SearchForm = (props: PropTypes): JSX.Element => {
    const {
        search,
        onSubmit,
    } = props;
    const { trackEvent } = useTrackEvent();
    const [newSearch, updateNewSearch] = useState<App.ProductSearch.Search>(search);
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const navigate = useNavigate();

    const { refetch } = useQueryProducts(accessToken, { ...newSearch, limit: 1 }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (data) => {
            if (data?.result?.length) {
                if (!window.location.toString().includes('/product/')) {
                    navigate(generatePath(PRODUCT_DETAILS, {
                        productKey: newSearch.productKey === null ? undefined : newSearch.productKey,
                    }));
                }
            } else if (!window.location.toString().includes('productKey=')) {
                trackEvent({ eventName: UserEvent.ProductNameSearch });
                onSubmit({ ...newSearch, productName: undefined });
            }
        },
    });

    const submitSearch = (submittedSearch: App.ProductSearch.Search): void => {
        // Filter out null/empty string and convert to undefined since qs treats undefined as "not present"
        const filteredSearch = Object.entries(submittedSearch).reduce((accum, [key, value]) => {
            // eslint-disable-next-line no-param-reassign
            accum[key] = value || undefined; // Simplify the conversion logic
            return accum;
        }, {} as App.ProductSearch.Search);

        if (filteredSearch.productKey) {
            refetch();
        } else {
            onSubmit(filteredSearch);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();
        const { name, value } = event.currentTarget;

        updateNewSearch({
            ...newSearch,
            [name]: value,
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        submitSearch(newSearch);
    };

    return (
        <StyledForm onSubmit={handleSubmit}>
            <StyledGrid
                container
                alignContent="center"
                justifyContent="flex-end"
                spacing={4}
                wrap="nowrap"
            >
                <Grid item>
                    <StyledTextField
                        fullWidth
                        disabled={!!newSearch.productKey}
                        label="Product Name"
                        margin="normal"
                        name="productName"
                        placeholder="Product Name"
                        size="small"
                        value={newSearch.productName}
                        variant="outlined"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <StyledTextField
                        fullWidth
                        aria-label="Search for Product by a key"
                        disabled={!!newSearch.productName}
                        label="Product Key"
                        margin="normal"
                        name="productKey"
                        placeholder="Product Key"
                        size="small"
                        value={newSearch.productKey}
                        variant="outlined"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        disabled={(!newSearch.productKey && !newSearch.productName)}
                        startIcon={(<Search />)}
                        type="submit"
                        variant="contained"
                    >
                        Search
                    </Button>
                </Grid>
            </StyledGrid>
        </StyledForm>
    );
};
