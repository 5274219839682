import { HTMLProps } from 'react';
import { styled } from '@mui/material';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { ErrorBoundary } from 'src/components/common/ErrorBoundary';

const StyledCSSGrid = styled(CSSGrid)(({ theme }) => ({
    flex: 1,
    gridGap: theme.spacing(4),
    gridTemplateAreas: `
        "header"
        "content"
    `,
    gridTemplateRows: 'auto 1fr',
    overflowY: 'auto',
}));

export const Main = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
    const { children } = props;

    return (
        <StyledCSSGrid>
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </StyledCSSGrid>
    );
};
