import defaultConfig from 'config/default.json';
import development from 'config/development.json';
import developmentLocal from 'config/development-local.json';
import production from 'config/production.json';
import productionProduction from 'config/production-production.json';
import productionQa from 'config/production-qa.json';

let local;

try {
    // eslint-disable-next-line global-require, import/no-unresolved
    local = require('./local.json');
} catch (e) {
    // @ts-expect-error
    if ((e as Error).code !== 'MODULE_NOT_FOUND') {
        throw e;
    }
    local = {};
}

type RecursivePartialAppConfig<T> = {
    [P in keyof T]?: RecursivePartialAppConfig<T[P]>;
};

export const files = {
    local,
    development,
    production,
    default: defaultConfig,
    'development-local': developmentLocal,
    'production-production': productionProduction,
    'production-qa': productionQa,
} as {
    [index: string]: RecursivePartialAppConfig<App.Config>;
};
