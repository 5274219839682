import {
    Box, Skeleton, styled, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { CardRow } from 'src/components/common/CardTable/CardRow';
import { CardCell } from 'src/components/common/CardTable/CardCell';

const PLACEHOLDERS: number[] = Array.from({ length: 6 }, (_, i) => i + 1);

const Container = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(4),
}));

const StyledTable = styled(Table)(({ theme }) => ({
    borderSpacing: `0 ${theme.spacing(4)}`,
    tableLayout: 'fixed',
}));

export const SearchResultsSkeleton = (): JSX.Element => (
    <Container>
        <StyledTable stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell align="center">STATUS</TableCell>
                    <TableCell align="center">PRODUCT KEY</TableCell>
                    <TableCell align="center" width="40%">PRODUCT NAME</TableCell>
                    <TableCell align="center">CURRENT VERSION</TableCell>
                    <TableCell align="center">MODIFIED DATE</TableCell>
                    <TableCell align="center">MODIFIED BY</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {PLACEHOLDERS.map((item) => (
                    <CardRow
                        hover
                        key={item}
                    >
                        <CardCell>
                            <Skeleton animation="wave" variant="rectangular" />
                        </CardCell>
                        <CardCell>
                            <Skeleton animation="wave" variant="rectangular" />
                        </CardCell>
                        <CardCell>
                            <Skeleton animation="wave" variant="rectangular" />
                        </CardCell>
                        <CardCell>
                            <Skeleton animation="wave" variant="rectangular" />
                        </CardCell>
                        <CardCell>
                            <Skeleton animation="wave" variant="rectangular" />
                        </CardCell>
                        <CardCell>
                            <Skeleton animation="wave" variant="rectangular" />
                        </CardCell>
                    </CardRow>
                ))}
            </TableBody>
        </StyledTable>
    </Container>
);
