import {
    Share, Schedule, ManageSearch, Image,
} from '@mui/icons-material';

import {
    PRODUCT_VISUALIZATIONS, PRODUCT_DETAILS_ASSOCIATIONS, PRODUCT_DETAILS_JOBS, PRODUCT_MATCHER_VALIDATIONS,
} from 'src/lib/routes';
import { NavBar, NavTabProps } from 'src/components/common/NavBar';

export type PropTypes = {
    disabled?: boolean;
};

export const ProductDetailsNavBar = (props: PropTypes): JSX.Element => {
    const { disabled } = props;
    const tabs: NavTabProps[] = [{
        key: 'associations',
        label: 'Product Associations',
        title: 'navigate to product associations',
        to: PRODUCT_DETAILS_ASSOCIATIONS,
        icon: <Share />,
        value: PRODUCT_DETAILS_ASSOCIATIONS,
    }, {
        key: 'jobs',
        label: 'Ingest Jobs',
        title: 'navigate to ingest jobs',
        to: PRODUCT_DETAILS_JOBS,
        icon: <Schedule />,
        value: PRODUCT_DETAILS_JOBS,
    }, {
        key: 'validations',
        label: 'Matcher Validations',
        title: 'navigate to matcher validations',
        to: PRODUCT_MATCHER_VALIDATIONS,
        icon: <ManageSearch />,
        value: PRODUCT_MATCHER_VALIDATIONS,
    }, {
        key: 'visualizations',
        label: 'Visualizations',
        title: 'navigate to visualizations',
        to: PRODUCT_VISUALIZATIONS,
        icon: <Image />,
        value: PRODUCT_VISUALIZATIONS,
    }];

    return (
        <NavBar
            aria-label="Click to view various product details"
            disabled={disabled}
            tabs={tabs}
        />
    );
};
