import { Check } from '@mui/icons-material';
import {
    Button, CircularProgress, styled, SvgIcon, useTheme,
} from '@mui/material';
import {
    ComponentProps, ComponentType, MouseEvent, useState,
} from 'react';

import { useSuccessEffect } from 'src/hooks/useSuccessEffect';
import { TitledButton } from 'src/components/common/buttons/TitledButton';

/**
 * This is a replacement for MUI's LoadingButton (@mui/lab)
 * to be used until whatever in @mui/lab is breaking webpack is fixed
 */
export type LoadingButtonProps = Omit<ComponentProps<typeof Button>, 'pending' | 'onClick'> & {
    component?: ComponentType;
    Icon?: typeof SvgIcon;
    iconProps?: ComponentProps<typeof SvgIcon>;
    onClick?: (event: MouseEvent<HTMLButtonElement>, abort?: AbortController) => (Promise<void> | void);
};

const SuccessCheck = styled(Check)(({ theme }) => ({
    color: theme.palette.success.main,
}));

export const LoadingButton = (props: LoadingButtonProps): JSX.Element => {
    const {
        children,
        disabled,
        Icon,
        iconProps = {},
        onClick,
        ...rest
    } = props;
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [successEffect, setSuccessEffect] = useSuccessEffect(false);

    const handleClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        if (!onClick) {
            return;
        }

        const abortController = new AbortController();

        try {
            setIsLoading(true);
            await onClick(event, abortController);
        } finally {
            if (!abortController.signal.aborted) {
                setSuccessEffect(true);
            }
            setIsLoading(false);
        }
    };

    const IconOrNull = Icon ? <Icon {...iconProps} /> : null;
    const DefaultIcon = successEffect ? <SuccessCheck {...iconProps} /> : IconOrNull;

    return (
        <TitledButton
            {...rest}
            disabled={isLoading || disabled}
            startIcon={isLoading ? <CircularProgress size={theme.spacing(5)} /> : DefaultIcon}
            onClick={handleClick}
        >
            {children}
        </TitledButton>
    );
};
